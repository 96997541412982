import React from "react"
import { Link } from 'gatsby'
import "normalize.css"
import "../styles/main.scss"
import ContactImage from "../components/contactImage";

const NotFoundPage = () => (
  <section className="page-404">
    <span className="rect">404</span>
    <div className="texts">
      <h5>A keresett oldal nem található</h5>
      <Link to="/" className="hp">vissza kezdőlapra</Link>
    </div>
    <ContactImage />
  </section>
)

export default NotFoundPage